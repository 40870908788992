/* @import "https://cdnjs.cloudflare.com/ajax/libs/cookieconsent2/3.1.0/cookieconsent.min.css"; */
cta {
  width: 100%;
}

#hideMe p {
  margin-bottom: 0 !important;
}

.alert {
  border-radius: 0;
  margin-bottom: 0;
}

.sweet {
  font-family: sweet-sans-pro, sans-serif;
  font-weight: 500;
  font-style: normal;
}

.lightgrey {
  background: #E1E2E4;
}

.lightgrey h3,
.lightgrey h3 em {
  font-family: minion-pro, serif;
}

.lightgrey hr {
  margin: 2rem 0;
}

.lightgrey img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.medgrey {
  color: #00000029;
}

.darkgrey {
  color: #707070;
}

.greengrey {
  color: #0D413D;
}

html {
  scroll-behavior: smooth;
}

html {
  font-size: calc(60% + 0.8vmin);
}

.prox-light {
  font-family: proxima-nova, sans-serif;
  font-weight: 300;
  font-style: normal;
}

.prox-med {
  font-family: proxima-nova, sans-serif;
  font-weight: 500;
  font-style: normal;
}

.prox-bold {
  font-family: proxima-nova, sans-serif;
  font-weight: 700;
  font-style: normal;
}

.minion-reg {
  font-family: minion-pro, serif;
  font-weight: 400;
  font-style: normal;
}

.sweet-sans {
  font-family: sweet-sans-pro, sans-serif;
  font-weight: 500;
  font-style: normal;
}

body {
  font-family: proxima-nova, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 1rem;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  color: #000000;
  letter-spacing: 0px;
  position: relative;
}

.h100,
#router-wrap {
  min-height: 100vh;
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
strong,
em,
b {
  filter: saturate(1.1);
}

h1,
.h1 {
  font-family: minion-pro, serif;
  font-style: normal;
  font-weight: 400;
  color: #000000;
  font-size: 2.813rem;
}

h2 {
  text-transform: uppercase;
  margin-bottom: 0;
}

h3 {
  text-transform: none;
  font-size: 2.813rem;
}

h4 {
  text-transform: uppercase;
  font-size: .938rem;
  font-weight: 700;
  letter-spacing: 6.3px;
}

h5 {
  letter-spacing: 6.3px;
  font-size: .938rem;
  text-transform: uppercase;
  font-weight: 300;
  margin-bottom: 2rem;
}

.bannerText {
  margin-top: 12.75rem;
}

.bannerText h2 {
  font-family: proxima-nova, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 0.938rem;
  color: #000000;
  letter-spacing: 6.3px;
  line-height: 1.64;
  margin-bottom: 0;
}

hr {
  background-color: #707070;
  width: 80px;
  opacity: 1;
}

ul {
  padding-inline-start: 20px;
}

main {
  z-index: 1;
}

p {
  margin-bottom: 0;
}

a {
  color: #FFFFFF;
  transition: all .2s linear;
  text-decoration: none;
}

a:hover,
a.active {
  color: #000000;
  transition: all .2s linear;
}

svg {
  transition: all .2s linear;
}

small.text-muted {
  color: #ffffff !important;
  font-size: .7rem !important;
  letter-spacing: 1.5px !important;
  font-family: proxima-nova, sans-serif;
  font-weight: 500;
}

.text-muted a {
  font-size: .975em !important;
  letter-spacing: 1.5px !important;
  font-family: proxima-nova, sans-serif;
  font-weight: 500;
}

.mtneg {
  margin-bottom: -2rem;
}

#services p {
  font-size: .938rem;
  line-height: 1.21;
}

.serviceLabel {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  text-transform: uppercase;
  font-size: .938rem;
  font-weight: 700;
  letter-spacing: 6.3px;
  color: #ffffff;
  background-color: rgba(0, 0, 0, .3);
  z-index: 3;
  transition: all .4s linear;
}

.serviceLink {
  position: relative;
}

.serviceLink:hover .serviceLabel {
  opacity: 1;
}

.serviceLink:hover img {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  -webkit-transition: .4s ease-in-out;
  transition: .4s ease-in-out;
}

.text-wrap {
  margin-top: 1rem;
  padding-right: 1rem;
  padding-left: 1rem;
  max-width: 275px;
}

.inlineBox {
  font-size: .938rem;
  color: #000;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 6.3px;
  border: 1px solid #000;
  padding: 1rem 4rem;
  background: #FFFFFF;
  display: inline-block;
  transition: all .6s ease-out;
  z-index: 2;
  position: relative;
}

.inlineCta {
  font-size: .938rem;
  color: #000;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 6.3px;
  border: 1px solid #000;
  padding: 1rem 0;
  height: 58px;
  background: #FFFFFF;
  display: inline-block;
  transition: all .6s ease-out;
  z-index: 2;
  position: relative;
}

.inlineCta:hover {
  color: #FFFFFF;
  background: #000;
}

.line {
  position: absolute;
  border-top: 1px solid #000;
  height: 1px;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  visibility: visible;
  opacity: 1;
  transition: visibility 1200ms linear 1000ms, opacity 1200ms;
}

.fadeIn {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 0ms, opacity 0ms;
}

.form-control {
  color: #000;
  background: transparent;
  border: 1px solid #000;
  border-radius: 0;
  opacity: 1;
  text-transform: uppercase;
  font-weight: 300;
  font-size: .938rem;
}

input[type]::placeholder,
textarea[type]::placeholder,
::placeholder,
textarea.form-control,
.form-control::placeholder {
  color: #000;
  opacity: 1;
}

textarea::-webkit-input-placeholder {
  color: #000;
  opacity: 1;
}

textarea:-moz-placeholder {
  color: #000;
  opacity: 1;
}

textarea::-moz-placeholder {
  color: #000;
  opacity: 1;
}

textarea:-ms-input-placeholder {
  color: #000;
  opacity: 1;
}

textarea::placeholder {
  color: #000;
  opacity: 1;
}

.border-black-3 {
  border: 1px solid #000;
  margin-top: -2rem;
  border-top: 1px solid #ffffff;
}

.border-top-fade {
  border-top: 1px solid #000;
  transition: border-top-color 1000ms ease;
}

.border-black {
  border: 1px solid #000;
  padding: 6rem 0;
  z-index: -1;
  margin-top: -2.25rem;
}

#clients p {
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 6.3px;
  font-size: .938rem;
  margin-bottom: 1.5rem;
}

#collapseExample {
  margin-top: -29px;

}

#collapseExample.collapsing {
  transition: height 1000ms ease !important;
}

#collapseExample .row-one {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

#collapseExample p {
  max-width: 50%;
}

#contactForm p {
  max-width: 100%;
}

#collapseExample a {
  color: #000;
  text-decoration: underline;
}

.btn,
.btn .btn-primary {
  color: #FFFFFF;
  background: #000;
  border-radius: 0;
  text-transform: uppercase;
  letter-spacing: 4.3px;
  font-size: .938rem;
  font-weight: 400;
  border: 1px solid #000;
  padding: .5rem 1.25rem .5rem 1.5rem;
  background: #000000;
  transition: all .6s ease-out;
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #FFFFFF;
  background: #000;
  border-radius: 0;
  text-transform: uppercase;
  border: 1px solid #000;
  background: #707070;
  opacity: 1;
  transition: all .6s ease-out;
}

.btn:hover,
.btn .btn-primary:hover {
  color: #000;
  background: #FFFFFF;
  border: 1px solid #000;
}


@media(max-width:768px) {

  .inlineCta {
    height: 46px;
  }

  #collapseExample {
    margin-top: -23px;
  }
}

@media(max-width:400px) {
  .inlineCta {
    height: 38px;
  }

  #collapseExample {
    margin-top: -19px;
  }
}

.sectionWrap {
  padding-top: 10rem;
  padding-bottom: 10rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.sectionWrap p {
  max-width: 450px;
}


#sections h3 {
  font-weight: 700;
  font-size: .938rem;
  letter-spacing: 6.3px;
  margin-bottom: 1.5rem;
  text-transform: uppercase;
  font-family: proxima-nova, sans-serif;
  line-height: 1.5;
}

.manage {
  max-height: 500px !important;
}

#sections p {
  margin-bottom: 1.5rem;
}

#sections .sectionWrap {
  padding-top: 4rem;
  padding-bottom: 4rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

#sections img {
  position: relative;
  z-index: 2
}

#sections .bg-even {
  position: absolute;
  bottom: 10%;
  right: 10%;
  z-index: 1
}

#sections .bg-odd {
  position: absolute;
  bottom: 10%;
  left: 10%;
  z-index: 1
}

@media(min-width:992px) {
  .inlineCta {
    width: 395px;
  }

  #sections .profile {
    height: 100%;
    object-fit: cover;
  }
}

@media(max-width:992px) {
  .inlineCta {
    width: 310px;
    padding: 1rem 0;
  }

  #sections .sectionWrap {
    padding-left: 0;
    padding-right: 0;
  }
}

@media(max-width:767px) {
  .inlineCta {
    width: 310px;
    padding: 1rem 4rem;
  }
}

@media(max-width: 500px) {
  #sections .profile {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  .inlineBox {
    padding: 1rem 0;
    width: 100%;
  }

  .inlineCta {
    width: auto;
    max-width: 100vw;
    padding: 1rem 1.5rem;
  }
}

.bg-image {
  background-color: #e3e4e6;
}

#mainContent {
  animation: fadeIn linear .8s;
}

.bg-image img,
.half-height .bg-image img {
  animation: fadeIn linear .5s;
}

#sections img,
#services img,
.lightgrey img {
  animation: fadeIn linear .5s;
}

@keyframes fadeIn {
  0% {
    opacity: 0%;
  }

  25% {
    opacity: 0%;
  }

  50% {
    opacity: 50%;
  }

  100% {
    opacity: 100%;
  }
}


#regText {
  background: transparent;
  color: hsl(0, 0%, 100%);
  font-size: 1.25rem;
  filter: saturate(1.5);
  transition: all .2s linear;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel img {
  width: 100vw;
  object-fit: cover;
  height: 100vh;
}


#home1 .carousel-item-next:not(.carousel-item-start),
#home1 .active.carousel-item-end {
  transform: translateX(100%);
}

#home1 .carousel-item-prev:not(.carousel-item-end),
#home1 .active.carousel-item-start {
  transform: translateX(-100%);
}

#home .carousel-item {
  height: 100vh;
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

#home .carousel-item.active,
#home .carousel-item-next.carousel-item-start,
#home .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
  transition: opacity 3s z-index 7.6s;
}

#home .active.carousel-item-start,
#home .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 3s z-index 7.6s;
}

@media (prefers-reduced-motion: reduce) {

  #home1 .active.carousel-item-start,
  #home1 .active.carousel-item-end {
    transition: none;
  }
}

#home1 .carousel-item {
  visibility: hidden;
  opacity: 0;
  display: block;
  transition: visibility 100ms linear 500ms, opacity 500ms;
  background: #DDC1B4;
}

#home1 .carousel-item.active {
  visibility: visible;
  opacity: 1;
  transition: visibility 1000ms linear 0s, opacity 700ms;
}

#home1 .carousel {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -2;
  background: #DDC1B4;
}

.full-height .bg-image {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -2;
}

.full-height .bg-image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.half-height .bg-image {
  width: 100vw;
  height: 100%;
  max-height: 70vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -2;
}

.half-height .bg-image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.star1 {
  margin-top: -.75rem;
}

.star2 {
  width: 47px;
  padding-bottom: .75rem;
  margin-top: -2rem;
}

.socialLink {
  font-family: 'Cap-Reg';
  font-size: 2.813rem;
  text-transform: lowercase;
  font-weight: normal;
  color: #1F3939;
}

#directions a,
#directions p {
  font-size: 1.25rem;
  margin-bottom: 0;
}

#directions a {
  text-decoration: underline;
}

#directions a:hover,
.border a:hover h2 {
  opacity: .7 !important;
}

#info p {
  min-height: 1px;
}

.link {
  position: relative;
  display: inline-block;
  text-decoration: none;
  overflow: hidden;
}

.link:hover .link__mask {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.link:hover .link__mask:after {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.link__text,
.link__mask:after {
  font-size: 2.5rem;
}

.link__text {
  color: #153625;
}

.link__mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transition: -webkit-transform 1s ease;
  transition: -webkit-transform 1s ease;
  transition: transform 1s ease;
  transition: transform 1s ease, -webkit-transform 1s ease;
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
  -webkit-transform-origin: left;
  transform-origin: left;
  overflow: hidden;
}

.link__mask:after {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #153625;
  -webkit-text-fill-color: #FFFFFF;
  -webkit-text-stroke-width: .5px;
  -webkit-text-stroke-color: #153625;
  -webkit-transition: inherit;
  transition: inherit;
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}

.sb {
  background: transparent;
  background-color: transparent;
  border: none;
  box-shadow: none;
  font-size: 0.938rem;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 1.27;
  color: #153625 !important;
}

.sb i {
  font-size: 1.25rem;
}

.btn.focus,
.btn:focus {
  box-shadow: none;
}

.btn-secondary {
  background-color: #153625;
  border-radius: 0;
  color: #FFFFFF;
  border-color: #153625;
  transition: all .5s ease;
  text-transform: uppercase;
  padding: .375rem 2.75rem;
}

.btn-secondary {
  float: left;
}

.btn-secondary:hover {
  background-color: transparent;
  border-radius: 0;
  color: #153625;
  border-color: #153625;
}

.scroll-to-top {
  position: fixed;
  bottom: 15px;
  right: 15px;
  opacity: 0;
  transition: all .2s ease-in-out;
}

.show-scrollTop {
  opacity: 1;
  transition: all .2s ease-in-out;
}

.green {
  color: #153625;
}

.bg-green {
  background: #153625;
}

.bg-pink {
  background: #DDC1B4;
}

.bg-muted-green {
  background-color: #6d7872;
}

@media screen and (prefers-contrast: more) {
  .bg-muted-green {
    background-color: #153625;
  }
}

.img-cover {
  width: 100%;
  object-fit: cover;
}

.p-8 {
  padding: 4rem 2rem 4rem 2rem;
}

.p4 {
  padding: 4rem;
}

.border {
  border-color: #D4BB81 !important;
  border-width: 2px !important;
}

.h-1 {
  height: 100%;
}

/* #Navigation
================================================== */
#navSocial a {
  padding: 0 .75rem;
}

#navSocial svg {
  height: 26px;
  width: 26px;
}

#navSocial a:hover svg {
  fill: #EEE1D5;
}

.start-header {
  opacity: 1;
  transform: translateY(0);
  padding: 0;
  box-shadow: 0 10px 30px 0 rgba(138, 155, 165, 0.15);
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.start-header.scroll-on {
  box-shadow: 0 5px 10px 0 rgba(138, 155, 165, 0.15);
  padding: 10px 0;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.start-header.scroll-on .navbar-brand img {
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.navigation-wrap {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.navbar {
  padding: 0;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.navbar-brand img {
  max-width: 100%;
  display: block;
  fill: #FFFFFF !important;
  filter: brightness(100%);
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.navbar-brand {
  height: 100%;
}

.navbar-brand svg {
  max-width: 100%;
  width: auto;
  height: 24px;
}

#footerWrap svg {
  width: auto;
  height: 23.5px;
}

.navbar-toggler {
  float: none;
  border: none;
  padding-right: 0;
  padding-top: 0 !important;
}

.navbar-light .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 1px solid #FFFFFF;
  transition: all 300ms linear;
}

.navbar-light .navbar-toggler-icon:after,
.navbar-light .navbar-toggler-icon:before {
  width: 24px;
  position: absolute;
  height: 1px;
  background-color: #FFFFFF;
  top: 0;
  left: 0;
  content: '';
  z-index: 2;
  transition: all 300ms linear;
}

.navbar-light .navbar-toggler-icon:after {
  top: 8px;
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg);
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg);
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent;
}

#menuContent .show,
.navbar-nav {
  background: #000000;
  transition: all 800ms ease;
  width: 100%;
}

#navbarCenter .nav-link {
  padding-left: 0;
  padding-right: 0;
  text-align: center;
  padding-top: 0;
}

.nav-link {
  color: #FFFFFF !important;
  transition: all 400ms linear;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 3.5px;
  text-transform: uppercase;
  text-align: center;
}

.nav-item:hover .nav-link {
  color: #FFFFFF !important;
}

.nav-item.active .nav-link {
  color: #FFFFFF !important;
}

.nav-link {
  position: relative;
  padding-bottom: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  display: inline-block;
}

.nav-link.active:after,
.nav-link:after {
  position: absolute;
  bottom: -5px;
  left: -1.5px;
  width: 100%;
  height: 1px;
  content: '';
  background-color: #FFFFFF;
  opacity: 0;
  transition: all 400ms linear;
}

.nav-link.active:after,
.nav-link:hover:after {
  bottom: -1px;
  opacity: 1;
}

.nav-item {
  position: relative;
  transition: all 400ms linear;
}

footer .form-control {
  width: 300px;
}

footer .btn-primary {
  background-color: transparent;
  border-radius: 0;
  color: #FFFFFF;
  border-color: #FFFFFF;
  transition: all .5s ease;
  text-transform: uppercase;
}

footer .btn-primary {
  float: right;
}

footer .btn-primary:hover {
  background-color: #FFFFFF;
  border-radius: 0;
  color: #153625;
  border-color: #FFFFFF;
}

/* #Home
================================================== */

#bannerWrap {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}

.banner-image {
  min-height: 100vh;
}

.img-overlay {
  position: absolute;
  background: rgba(0, 0, 0, .4);
  height: 100%;
  min-height: 100vh;
  width: 100%;
}

#one {
  box-shadow: 0 -10px 30px 0 rgba(138, 155, 165, 0.15);
}

#one h2 {
  font-weight: 700;
  font-size: .938rem;
  letter-spacing: 6.3px;
  margin-bottom: 1.5rem;
}

#largeCta {
  width: 100%;
  height: 350px;
}

@media(min-width: 1400px) {
  #one .container-fluid {
    max-width: 1229px;
  }
}

.full-height {
  min-height: 100vh;
  width: 100vw;
}

.half-height {
  min-height: 70vh;
  width: 100vw;
}

.img-wrap {
  max-width: 445px;
  z-index: 1;
}

.arrow {
  cursor: pointer;
  height: 40px;
  left: 50%;
  position: absolute;
  bottom: 0;
  transform: translateX(-50%) translateY(-50%);
  transition: transform .1s;
  width: 40px;
}

#rot {
  transform: rotate(90deg);
}

.arrow-top,
.arrow-bottom {
  background-color: #FFFFFF;
  height: 4px;
  left: -5px;
  position: absolute;
  top: 50%;
  width: 100%;
}

.arrow-top:after,
.arrow-bottom:after {
  background-color: #153625;
  content: '';
  height: 100%;
  position: absolute;
  top: 0;
  transition: all 0.15s;
}

.arrow-top {
  transform: rotate(45deg);
  transform-origin: bottom right;
}

.arrow-top:after {
  left: 100%;
  right: 0;
  transition-delay: 0s;
}

.arrow-bottom {
  transform: rotate(-45deg);
  transform-origin: top right;
}

.arrow-bottom:after {
  left: 0;
  right: 100%;
  transition-delay: 0.15s;
}

.arrow:hover .arrow-top:after {
  left: 0;
  transition-delay: 0.15s;
}

.arrow:hover .arrow-bottom:after {
  right: 0;
  transition-delay: 0s;
}

.arrow:active {
  transform: translateX(-50%) translateY(-50%) scale(0.9);
}

/* #Menus, #Contact, #About, #Private-Dining
================================================== */
#menus .card {
  border: none;
}

#menus h1,
#private h1 {
  font-size: 5rem;
  letter-spacing: 3.5px;
}

#menus h2,
#private h2 {
  min-width: 175px;
  text-align: center;
}

#menu h3,
#private h3 {
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 0.3px;
}

#menu p,
#private p {
  font-size: 1rem;
  letter-spacing: 0.3px;
}

#private a {
  color: #153625;
}

#private a:hover,
#private a:hover p {
  opacity: .7;
}

.menu-header button {
  position: relative;
  text-transform: uppercase;
  text-decoration: none;
  color: #153625;
}

.menu-header button:hover,
.menu-header button:focus {
  color: #153625;
  text-decoration: none;
}

.menu-header button:after {
  position: absolute;
  content: '';
  height: 2px;
  bottom: 0;
  margin: 0 auto;
  left: 0;
  right: 0;
  width: 70%;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  background-color: transparent;
  border-bottom: 0px;
}

.menu-header button:hover:after {
  background-color: #153625;
  border-bottom: 0px;
}

.menu-header button[aria-expanded='true']:after {
  background-color: #153625;
  border-bottom: 0px;
}

.accordion .card-body {
  min-height: 100%;
  padding: 1.25rem 2.5rem;
}

.dnone {
  width: 0;
  height: 0;
  opacity: 0;
}

.dblock {
  opacity: 1;
  transition: opacity 1s;
}

/* #Footer
================================================== */
#fa {
  padding-top: .25rem;
  padding-right: .5rem;
}

.site-footer {
  padding: 40px 0;
}

.site-footer hr {
  border-top-color: #FFFFFF;
  background-color: #FFFFFF;
  opacity: 1;
  margin-top: 0;
  width: 100%;
}

.site-footer hr.small {
  margin: 20px 0
}

.site-footer h6 {
  color: #FFFFFF;
  font-size: 1.25rem;
  text-transform: uppercase;
  margin-top: 5px;
}

.site-footer a,
.site-footer p {
  font-family: proxima-nova, sans-serif;
  font-weight: 500;
  color: #FFFFFF;
  background: transparent;
  color: hsl(0, 0%, 100%);
  font-size: .875rem;
  transition: all .2s linear;
  letter-spacing: 3.5px;
}

.site-footer a:hover {
  color: #707070 !important;
  transition: all .2s linear;
}

.site-footer a:hover,
.site-footer a:hover p {
  color: #707070 !important;
  transition: all .2s linear;
}

.footer-links {
  padding-left: 0;
  list-style: none
}

.footer-links li {
  display: block
}

.footer-links.inline li {
  display: inline-block
}

.site-footer .social-icons {
  text-align: right
}

.site-footer .social-icons a {
  width: 40px;
  height: 40px;
  line-height: 40px;
  margin-left: 6px;
  margin-right: 0;
  border-radius: 100%;
  background-color: #FFFFFF
}

.copyright-text {
  margin: 0
}

@media (min-width:767px) {

  .site-footer .copyright-text,
  .site-footer .social-icons {
    text-align: center
  }

  .container-md-fluid {
    max-width: 100% !important;
    padding: 0;
  }
}

.social-icons {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none
}

.social-icons li {
  display: inline-block;
  margin-bottom: 4px
}

.social-icons li.title {
  margin-right: 15px;
  text-transform: uppercase;
  color: #96a2b2;
  font-weight: 700;
  font-size: 0.813rem;
}

.social-icons a {
  background-color: #FFFFFF;
  color: #153625;
  font-size: 1rem;
  display: inline-block;
  line-height: 44px;
  width: 44px;
  height: 44px;
  text-align: center;
  margin-right: 8px;
  border-radius: 100%;
  -webkit-transition: all .2s linear;
  -o-transition: all .2s linear;
  transition: all .2s linear
}

.social-icons a:active,
.social-icons a:focus,
.social-icons a:hover {
  color: #fff;
}

.social-icons.size-sm a {
  line-height: 34px;
  height: 34px;
  width: 34px;
  font-size: 0.875rem;
}

.social-icons a.facebook:hover {
  background-color: #3b5998
}

.social-icons a.instagram:hover {
  background-color: #e4405f
}

.social-icons a.twitter:hover {
  background-color: #00aced
}

.social-icons a.linkedin:hover {
  background-color: #007bb6
}

.social-icons a.dribbble:hover {
  background-color: #ea4c89
}

@media(max-width:1200px) {
  #infoContainer {
    position: relative;
  }

  .p-8 {
    padding: 3rem 2rem;
  }
}

@media(min-width:1200px) {
  .navbar-expand-lg .navbar-nav {
    width: 100%;
  }
}

@media(min-width:992px) {
  .navbar-expand-lg .navbar-nav {
    width: 100%;
    justify-content: center !important;
  }

  #menuContent .nav-item {
    min-width: 130px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 2rem;
  }

  #menuContent .navlogo {
    width: auto;
  }
}

@media (hover: none) {
  #menuContent .nav-item {
    min-width: 1px;
  }

  .full-height {
    min-height: 75vh;
  }

  .full-height .bg-image {
    height: 75vh;
  }
}

@media (max-width:992px) {
  .navbar {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    padding: 1.25rem;
  }

  .navbar-collapse ul {
    padding-top: 2.5rem;
    height: 100vh;
  }

  .navbar-brand {
    margin: auto;
  }

  #introWrap {
    max-width: 100%;
  }

  .nav-item {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .nav-link {
    font-size: 1.25rem;
  }

  .nav-item.active:after,
  .nav-item:hover:after {
    bottom: 10px;
  }

  #socialM {
    padding-top: 6rem;
  }

  #socialM a {
    font-size: 1.313rem;
    color: #FFFFFF
  }

  .full-height {
    min-height: 75vh;
  }

  .full-height .bg-image {
    height: 75vh;
  }

  .bannerText {
    margin-top: 8.35rem;
  }

  .p-8 {
    padding: 2rem;
  }

  #infoContainer {
    position: relative;
  }

  footer .form-control {
    width: 200px;
  }

  .site-footer hr {
    margin-top: 1rem;
  }
}

@media (max-width:767px) {
  .p-8 {
    padding: 2rem;
  }

  .sectionWrap {
    padding-top: 6rem;
    padding-bottom: 6rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .inlineCta {
    padding: .75rem 1rem;
  }

  .bannerText {
    margin-top: 10rem;
  }

  #home1 .bg-image img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  #menus h2 {
    font-size: 1.75rem;
    text-align: center;
    min-width: 75px;
  }

  footer .btn-primary {
    float: none;
  }

  .site-footer hr {
    border-top-color: #FFFFFF;
    background-color: #FFFFFF;
    opacity: 1;
    width: 100%;
  }

  .site-footer .nav-item {
    padding-top: 0;
    padding-bottom: 0;
  }

  .social-icons li.title {
    display: block;
    margin-right: 0;
    font-weight: 600
  }
}

@media (max-width:500px) {
  .navbar {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .navbar-light .navbar-toggler {
    padding: 0;
    margin: auto;
  }

  .navbar-brand svg,
  #footerWrap svg {
    max-width: 90vw;
  }

  h2,
  .socialLink {
    font-size: 2.188rem;
  }

  #navSocial a {
    padding: 0 .5rem;
  }

  #navSocial svg {
    height: 20px;
    width: 20px;
  }

  h1,
  .h1 {
    font-size: 2.813rem;
  }

  .img-wrap {
    max-width: 90%
  }

  .star1 {
    margin-top: -.5rem;
  }

  .p-8 {
    padding: .75rem;
  }
}

@media(max-width: 768px) {
  .half-height {
    min-height: 50vh;
  }

  .half-height .bg-image {
    width: 100vw;
    height: 100%;
    max-height: 50vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -2;
  }
}


@media (max-width:410px) {
  .navbar-brand {
    margin-bottom: 1rem;
  }

  .bannerText h2 {
    letter-spacing: 4.3px;
  }
}